var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
// @ts-nocheck
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import { Datagrid, FunctionField, List, useDataProvider, useResourceContext, useTranslate } from 'react-admin';
import { dbDateHelper, } from '../utils/helper';
import { Button, Chip, IconButton, useMediaQuery } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import globalStyles from "../styles/globalStyles";
var PaymentRecord = function (props) {
    var translate = useTranslate();
    var dataProvider = useDataProvider();
    var resource = useResourceContext(props);
    var isTabScreen = useMediaQuery("(max-width: 930px)");
    var rowStyle = isTabScreen ? {
        display: "flex",
        flexWrap: "wrap",
    } : {};
    var rowCellStyle = isTabScreen ? {
        width: "100%"
    } : {};
    var rowHeaderStyle = isTabScreen ? {
        display: "none"
    } : {};
    var postRowStyle = function (record, index) { return ({
        // backgroundColor: isTabScreen && index%2==0 ? 'WhiteSmoke' : 'white',
        border: '1px solid Gainsboro'
    }); };
    return (_jsx(Grid, { padding: 2, children: _jsxs(Card, { style: {
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                borderRadius: '20px',
                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.3)',
                borderBottom: '1px solid rgba(0, 0, 0, 0.3)'
            }, children: [_jsx(CardHeader, { title: _jsx(Box, { sx: { display: 'flex', justifyContent: 'center', width: '100%' }, children: _jsx("span", { children: translate('paymentRecord.transactionRecord') }) }), style: {
                        background: 'linear-gradient(45deg, #1976D2 30%, #1CA2E3 90%)',
                        color: 'white',
                        padding: '10px',
                        borderTopLeftRadius: '20px',
                        borderTopRightRadius: '20px',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center'
                    }, titleTypographyProps: { variant: 'subtitle1', fontWeight: 900 } }), _jsx(CardContent, { style: {
                        width: '100%',
                        padding: 0,
                    }, children: _jsx(List, { resource: "payment/records", sx: {
                            '& .RaList-main': {
                                padding: '16px 24px',
                                backgroundColor: '#fff',
                            },
                            '& .MuiTableHead-root': {
                                backgroundColor: '#f5f5f5',
                                '& .MuiTableCell-head': {
                                    fontWeight: 600,
                                    color: '#424242',
                                    fontSize: '0.875rem',
                                    padding: '12px 16px',
                                    textAlign: 'center', // Added center alignment
                                }
                            },
                            '& .MuiTableBody-root': {
                                '& .MuiTableRow-root': {
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5',
                                        transition: 'background-color 0.2s ease',
                                    },
                                    '& .MuiTableCell-body': {
                                        padding: '16px',
                                        borderBottom: '1px solid #e0e0e0',
                                        fontSize: '0.875rem',
                                        textAlign: 'center', // Added center alignment
                                    }
                                },
                                '& .MuiTableRow-root:last-child .MuiTableCell-body': {
                                    borderBottom: 'none'
                                }
                            }
                        }, children: _jsxs(Datagrid, { style: { width: '100%', tableLayout: "fixed" }, sx: {
                                '& .RaDatagrid-row': rowStyle,
                                '& .RaDatagrid-headerCell': rowHeaderStyle,
                                '& .RaDatagrid-rowCell': rowCellStyle,
                            }, bulkActionButtons: false, rowStyle: postRowStyle, children: [_jsx(FunctionField, { label: translate('paymentRecord.transactionId'), render: function (record) {
                                        var _a, _b;
                                        return (_jsxs("div", { style: __assign(__assign({}, globalStyles.centerContainer), { justifyContent: 'center' }), children: [isTabScreen && (_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("pages.subject.fields.educationLevel"), ":"] })), _jsx(Box, { sx: {
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        width: '12em',
                                                        margin: '0 auto', // Center the box
                                                    }, children: _jsxs(Box, { sx: {
                                                            position: 'relative',
                                                            width: '100%',
                                                            display: 'flex',
                                                            alignItems: 'center',
                                                            border: '1px solid #e0e0e0',
                                                            borderRadius: '4px',
                                                            padding: '4px 8px',
                                                            paddingRight: '32px',
                                                        }, children: [_jsx(Typography, { sx: {
                                                                    overflow: 'hidden',
                                                                    textOverflow: 'ellipsis',
                                                                    whiteSpace: 'nowrap',
                                                                    width: '100%',
                                                                    textAlign: 'center', // Center the ID text
                                                                }, children: ((_a = record === null || record === void 0 ? void 0 : record.id) === null || _a === void 0 ? void 0 : _a.length) > 15 ? "".concat(record === null || record === void 0 ? void 0 : record.id.substring(0, 15), "...") : (_b = record === null || record === void 0 ? void 0 : record.id) !== null && _b !== void 0 ? _b : "" }), _jsx(IconButton, { size: "small", onClick: function () {
                                                                    var _a;
                                                                    navigator.clipboard.writeText((_a = record === null || record === void 0 ? void 0 : record.id) !== null && _a !== void 0 ? _a : "");
                                                                }, sx: {
                                                                    position: 'absolute',
                                                                    right: 2,
                                                                    padding: '4px',
                                                                    '&:hover': {
                                                                        backgroundColor: 'rgba(0, 0, 0, 0.04)'
                                                                    }
                                                                }, children: _jsx(ContentCopyIcon, { fontSize: "small" }) })] }) })] }));
                                    } }), _jsx(FunctionField, { label: translate('paymentRecord.transactionDate'), render: function (record) {
                                        var _a, _b;
                                        return (_jsx(Typography, { children: isTabScreen ?
                                                _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("paymentRecord.transactionDate"), ":"] }), _jsx(Typography, { className: "small-text", children: dbDateHelper((_a = record === null || record === void 0 ? void 0 : record.created_at) !== null && _a !== void 0 ? _a : "") })] })
                                                : dbDateHelper((_b = record === null || record === void 0 ? void 0 : record.created_at) !== null && _b !== void 0 ? _b : "") }));
                                    } }), _jsx(FunctionField, { source: "product", label: translate('paymentRecord.product'), render: function (record) {
                                        var _a, _b;
                                        return (_jsx(Typography, { children: isTabScreen ?
                                                _jsxs("div", { style: globalStyles.centerContainer, children: [_jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate('paymentRecord.product'), ":"] }), _jsx(Typography, { className: "small-text", children: (_a = record === null || record === void 0 ? void 0 : record.product) !== null && _a !== void 0 ? _a : "" })] })
                                                : (_b = record === null || record === void 0 ? void 0 : record.product) !== null && _b !== void 0 ? _b : "" }));
                                    } }), _jsx(FunctionField, { label: translate('paymentRecord.status'), render: function (record) { return (_jsxs("div", { style: __assign(__assign({}, globalStyles.centerContainer), { justifyContent: 'center' }), children: [isTabScreen &&
                                                _jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("paymentRecord.status"), ":"] }), _jsx(Button, { variant: "contained", size: "small", color: (record === null || record === void 0 ? void 0 : record.status) === 'Completed' ? 'success' : 'error', sx: {
                                                    minWidth: '100px',
                                                    textTransform: 'capitalize',
                                                    borderRadius: '10px'
                                                }, children: (record === null || record === void 0 ? void 0 : record.status) ? translate("params.dashboard.paymentRecord.".concat(record.status)) : "" })] })); } }), _jsx(FunctionField, { label: translate('paymentRecord.buyerInformation'), render: function (record) {
                                        var _a, _b, _c, _d, _e, _f, _g, _h;
                                        return (_jsxs("div", { style: __assign(__assign({}, globalStyles.centerContainer), { justifyContent: 'center' }), children: [isTabScreen &&
                                                    _jsxs(Typography, { style: globalStyles.tabSizeAddedTitle, children: [translate("paymentRecord.buyerInformation"), ":"] }), _jsxs(Box, { sx: {
                                                        display: 'flex',
                                                        flexWrap: "wrap",
                                                        alignItems: 'center',
                                                        justifyContent: "center",
                                                        gap: 1
                                                    }, children: [_jsx(Typography, { children: (_b = (_a = record === null || record === void 0 ? void 0 : record.buyer) === null || _a === void 0 ? void 0 : _a.username) !== null && _b !== void 0 ? _b : "" }), _jsx(Chip, { label: ((_c = record === null || record === void 0 ? void 0 : record.organization) === null || _c === void 0 ? void 0 : _c.orgName) ?
                                                                (((_e = (_d = record === null || record === void 0 ? void 0 : record.organization) === null || _d === void 0 ? void 0 : _d.orgName) === null || _e === void 0 ? void 0 : _e.length) > 35 ?
                                                                    "".concat((_f = record === null || record === void 0 ? void 0 : record.organization) === null || _f === void 0 ? void 0 : _f.orgName.substring(0, 35), "...")
                                                                    : (_h = (_g = record === null || record === void 0 ? void 0 : record.organization) === null || _g === void 0 ? void 0 : _g.orgName) !== null && _h !== void 0 ? _h : "") : "", size: "medium", sx: {
                                                                backgroundColor: '#e3f2fd',
                                                                color: '#1976d2',
                                                                fontWeight: 500,
                                                                borderRadius: '16px',
                                                                marginTop: '0.2em',
                                                            } })] })] }));
                                    } })] }) }) })] }) }));
};
export default PaymentRecord;
